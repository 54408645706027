export const PitchResponseChoices = {
  1: "yes",
  2: "no",
  3: "maybe",
};
export const PitchDeclinedReasonChoices = {
  0: "none",
  1: "not_looking",
  2: "not_interested_role",
  3: "not_interested_tech_stack",
  4: "bad_company_impression",
  5: "prefers_other_company",
  6: "salary_concern",
  7: "working_language_concern",
};

export const PipelineStageChoices = {
  1: "pitching",
  2: "waiting",
  3: "ok_to_send",
  4: "introduced",
  5: "coding_test",
  6: "ccm_1",
  7: "ccm_2",
  8: "ccm_3",
  9: "ccm_4_plus",
  10: "final",
  11: "offer",
  20: "offer_accepted",
  90: "offer_declined",
  91: "esai_rejected",
  92: "client_rejected",
  93: "candidate_withdrew",
  94: "candidate_ghosted",
};
export const PipelineStageText = {
  pitching: "Pitching",
  waiting: "Waiting",
  ok_to_send: "Ready for Introduction",
  introduced: "Resume Screening",
  coding_test: "Test",
  ccm_1: "1st Interview",
  ccm_2: "2nd Interview",
  ccm_3: "3rd Interview",
  ccm_4_plus: "4th Interview+",
  final: "Final Interview",
  offer: "Offer Extended",
  offer_accepted: "Offer Accepted",
  offer_declined: "Offer Declined",
  esai_rejected: "ESAI Rejected",
  client_rejected: "Rejected by Company",
  candidate_withdrew: "Candidate Withdrew",
  candidate_ghosted: "Candidate Ghosted",
};
export const sortByOptions = [
  { label: "Name", value: "name" },
  { label: "Position", value: "position" },
  { label: "Company", value: "company" },
];
export const exportOptions = [
  "This Week",
  "This Month",
  "This Year",
  "Custom Dates",
];

export const languageShortAppOptions = [
  { label: "English", value: "en" },
  { label: "Japanese", value: "ja" },
];

export const comapanyOptions = [
  { value: "enterprise", label: "Enterprise" },
  { value: "startup", label: "Start Up" },
];
export const ChartsColor = ["#6754E2", "#00C99C", "#FCA249", "#10c1e5"];

export const reportOption = [
  "Applicant Report",
  "Client Applicant Report",
  "Pipeline Report",
];
export const userTypeOption = ["Admin", "Recruiter/Sales", "Client"];
export const actionOption = ["Ascending", "Descending"];

export const pieChartNameConvention = [
  { name: "percent_applied", value: "yes" },
  { name: "percent_declined", value: "no" },
  { name: "percent_maybe", value: "maybe" },
];

export const applicantReportType = {
  yes: "percent_applied",
  no: "percent_declined",
  maybe: "percent_maybe",
};

export const barChartNameConvention = [
  { name: "percent_bad_company_impression", value: "Bad Company Impression" },
  { name: "percent_not_interested_role", value: "Not Interested Role" },
  {
    name: "percent_not_interested_tech_stack",
    value: "Not Interested Tech Stack",
  },
  { name: "percent_not_looking", value: "Not Looking" },
  { name: "percent_prefers_other_company", value: "Prefers Other Company" },
  { name: "percent_salary_concern", value: "Salary Concern" },
  {
    name: "percent_working_language_concern",
    value: "Working Language Concern",
  },
];

export const DefaultBarData = [
  { name: "Type 1", value: 5, value1: 5, value2: 4 },
  { name: "Type 2", value: 8, value1: 8, value2: 3 },
  { name: "Type 3", value: 14, value1: 4, value2: 2 },
  { name: "Type 3", value: 12, value1: 7, value2: 7 },
  { name: "Type 3", value: 15, value1: 9, value2: 8 },
];

export const reportTypeOptions = [
  { label: "YES", value: "yes" },
  { label: "NO", value: "no" },
  { label: "MAYBE", value: "maybe" },
];

export const dashboardLineChartDatakeys = ["yes", "no", "maybe"];
export const dashboardLineChartDataLabels = [
  { name: "Yes" },
  { name: "No" },
  { name: "Maybe" },
];

export const reportBarChartDatakeys = ["company", "market", "industry"];
export const reportLineChartDatakeys = ["company", "market", "industry"];
export const reportLineChartDataLabels = [
  { name: "Your Company" },
  { name: "Market" },
  { name: "Industry" },
];

export const ComparisonStartDates = {
  yes: new Date("March 1, 2023"),
  no: new Date("June 1, 2023"),
  maybe: new Date("March 1, 2023"),
  candidatesReport: new Date("Jan 1, 2022"),
};

// line chart date calculations
export const today = new Date();
export const quarter = Math.floor(today.getMonth() / 3);
export const thisWeekStart = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 6
);
export const thisMonthStart = new Date(
  today.getFullYear(),
  today.getMonth(),
  1
);
export const thisMonthEnd = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0,
  23,
  59,
  59
);
export const lastThreeMonthsStart = new Date(
  today.getFullYear(),
  today.getMonth() - 2,
  1
);
export const lastThreeMonthsEnd = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0,
  23,
  59,
  59
);
export const thisQuarterStart = new Date(today.getFullYear(), quarter * 3, 1);
export const thisQuarterEnd = new Date(
  thisQuarterStart.getFullYear(),
  thisQuarterStart.getMonth() + 3,
  0,
  23,
  59,
  59
);

export const FetchLineDataDates = {
  month: { startDate: thisWeekStart, endDate: today },
  quarter: { startDate: thisMonthStart, endDate: thisMonthEnd },
  year: { startDate: thisQuarterStart, endDate: thisQuarterEnd },
};

export const FetchLineDataUnit = {
  year: "quarter",
  quarter: "month",
  month: "custom",
  custom: "custom",
};

export const FetchLineDataCount = {
  year: 4,
  quarter: 3,
  month: 4,
};

export const barChartNoReasons = {
  "Bad Company Impression": "badCompanyImpression",
  "Not Interested Role": "notInterestedRole",
  "Not Interested Tech Stack": "notInterestedTechStack",
  "Not Looking": "notLooking",
  "Prefers Other Company": "prefersOtherCompany",
  "Salary Concern": "salaryConcern",
  "Working Language Concern": "workingLanguageConcern",
};

export const reportCardsDataForExternal = [
  {
    title: "Employer Branding Report",
    description:
      "Provides performance metrics about candidates who have not said yes.",
    navigate: "/performancereport",
  },
  {
    title: "Active Pipeline Report",
    description:
      "This is a pipeline of all active candidates between stages of CV sent to Offer Accepted (Pending Start date)",
    navigate: "/activepipelinereport",
  },
];

export const reportCardsDataForInternal = [
  {
    title: "Internal Candidate Pipeline Report",
    navigate: "/candidatepipelinereport",
  },
  {
    title: "Recently Active Candidates",
    navigate: "/recentlyactivecandidatesreport",
  },
];

export const reportCardsDataForBothUser = [
  {
    title: "Success KPI",
    navigate: "/successkpireport",
  },
  {
    title: "Time to Hire",
    navigate: "/timetohirereport",
  },
  {
    title: "Billings Report",
    navigate: "/billingsreport",
  },
];

export const reportCardsDataForAll = [
  ...reportCardsDataForExternal,
  ...reportCardsDataForInternal,
  ...reportCardsDataForBothUser,
];

export const loaderMessages = [
  "Gathering Info",
  "Fetching Work Data",
  "Fetching Jobs",
  "Generating Excel Report",
  "Fetching Company Data",
  "Applying Filters",
  "Fetching Client Data",
];

export const ActivePipelineExportColumnList = [
  "first_name",
  "last_name",
  "current_employer",
  "current_title",
  "salary",
  "salary_breakdown",
  "english",
  "japanese",
  "kanji_first",
  "kanji_last",
  "linkedin_url",
  "twitter_url",
  "facebook_url",
  "github_url",
  "birthday",
  "education_history",
  "work_history",
  "initial_response",
  "response_reason",
  "app_position_name",
  "client_name",
  "focus",
  "current_stage",
  "role",
];

export const CandidatePipelineExportColumnList = [
  ...ActivePipelineExportColumnList,
  "business_manager",
  "candidate_manager",
  "text",
  "other_applications",
  "zoho_url",
  "trello_url",
  "outreach_url",
];

export const ClientPipelineExportColumnList = [
  ...ActivePipelineExportColumnList,
  "text",
  "other_applications",
];

/* These values correspond to the EmployeeFilterItem.role */
export const EmployeeRoleChoices = {
  10: "assistant",
  20: "candidate_manager_1",
  21: "candidate_manager_2",
  22: "candidate_manager_3",
  30: "business_manager_1",
  31: "business_manager_2",
  32: "business_manager_3",
  50: "partner",
};

export const reportCardColors = [
  "#6754E2",
  "#00C99C",
  "#FCA249",
  "#10c1e5",
  "#e510be",
  "#152de5",
];

export const reportsCategoryColors = {
  top: "#6754E2",
  custom: "#00C99C",
  all: "#FCA249",
};

// export const internalReportsPath = ["/candidatepipelinereport","clientpipelinereport"]
export const internalReportsPath = [
  "/candidatepipelinereport",
  "/recentlyactivecandidatesreport",
  "/candidatepipelinereport/leaderboard",
  "/recentlyactivecandidatesreport/leaderboard",
];

//used in both internal and external and depends on type of user
export const differentReportsPath = [
  "/billingsreport",
  "/timetohirereport",
  "/successkpireport",
  "/timetohirereport/leaderboard",
  "/successkpireport/leaderboard",
  "/billingsreport/leaderboard",
];

export const defaultStage = [4, 5, 6, 7, 8, 9, 10, 11, 20];

export const FILTER_TYPES = {
  LEFT_HAND_FILTERS: "left_hand_filters",
  TABLE_FILTERS: "table_filters",
  TABLE_SORTING: "table_sorting",
};

export const filterTypesOptions = [
  {
    label: "Left-hand Filters",
    value: "left_hand_filters",
  },
  {
    label: "Table Filters",
    value: "table_filters",
  },
  {
    label: "Table Sorting Options",
    value: "table_sorting",
  },
];

export const APPLY_FILTER_PAGE = {
  active_pipeline: "applyFilterInActivePipeline",
  candidate_pipeline: "applyFilterInCandidatePipeline",
  dashboard: "applyFilterInDashboard",
  employee_branding: "applyFilterInEmployeeBranding",
  success_kpi: "applyFilterInSuccessKpi",
  recently_active_candidate: "applyFilterInRecentlyActiveCandidate",
  time_to_hire: "applyFilterInTimeToHire",
  jobs: "applyFilterInJobs",
  billingsReport: "applyFilterInBillingsReport",
};

export const dateOptions = {
  timeZone: "Asia/Tokyo",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const PAGES = {
  DASHBOARD: "dashboard",
  ACTIVE_PIPELINE: "active_pipeline",
  CANDIDATE_PIPELINE: "candidate_pipeline",
  TIME_TO_HIRE: "time_to_hire",
  SUCCESS_KPI: "success_kpi",
  RECENTLY_ACTIVE_CANDIDATES: "recently_active_candidate",
  EMPLOYEE_BRANDING: "employee_branding",
  JOBS: "jobs",
  CANDIDATES: "candidates",
  BILLINGS_REPORT: "billings_report",
};

export const reportToFilterKeysMappingForInternalUser = Object.seal({
  dashboard: [
    "Positions",
    "Speciality",
    "startDate",
    "endDate",
    "count",
    "unit",
  ],
  active_pipeline: ["Positions", "Speciality"],
  candidate_pipeline: [
    "Positions",
    "Speciality",
    "BusinessManagers",
    "CandidateManagers",
    "StageManagers",
    "BusinessManagers",
    "ClientManagers",
    "startDate",
    "endDate",
  ],
  time_to_hire: [
    "Positions",
    "Speciality",
    "BusinessManagers",
    "CandidateManagers",
    "StageManagers1",
    "StageManagers2",
    "ClientManagers",
    "startDate",
    "endDate",
    "count",
    "unit",
  ],
  success_kpi: [
    "Positions",
    "Speciality",
    "BusinessManagers",
    "CandidateManagers",
    "StageManagers",
    "ClientManagers",
    "startDate",
    "endDate",
    "count",
    "unit",
  ],
  recently_active_candidate: [
    "Positions",
    "Speciality",
    "CandidateManagers",
    "StageManagers",
    "ClientManagers",
    "startDate",
    "endDate",
  ],
  employee_branding: [
    "Positions",
    "Speciality",
    "startDate",
    "endDate",
    "count",
    "unit",
    "Industry",
    "PitchResponse",
    "StageManagers",
  ],
  jobs: [
    "Positions",
    "Speciality",
    "ClientManagers",
    "Industry",
    "RemotePolicy",
    "Visibility",
    "Status",
    "RequiredLanguages",
    "ActiveSearching",
    "startDate",
    "endDate",
  ],
  candidates: [],
  billings_report: [
    "Positions",
    "Speciality",
    "BusinessManagers",
    "CandidateManagers",
    "ClientManagers",
    "Background",
    "startDate",
    "endDate",
    "count",
    "unit",
    "ApplyToStart",
  ],
});

export const reportToFilterKeysMappingForExternalUser = Object.seal({
  dashboard: [
    "Positions",
    "Speciality",
    "startDate",
    "endDate",
    "count",
    "unit",
  ],
  active_pipeline: ["Positions", "Speciality", "StageManagers"],
  time_to_hire: [
    "Positions",
    "Speciality",
    "StageManagers1",
    "StageManagers2",
    "startDate",
    "endDate",
    "count",
    "unit",
    "Industry",
  ],
  success_kpi: [
    "Positions",
    "Speciality",
    "StageManagers",
    "startDate",
    "endDate",
    "count",
    "unit",
    "Industry",
  ],
  employee_branding: [
    "Positions",
    "Speciality",
    "startDate",
    "endDate",
    "count",
    "unit",
    "Industry",
    "PitchResponse",
    "StageManagers",
  ],
  jobs: [
    "Positions",
    "Speciality",
    "Industry",
    "RemotePolicy",
    "Visibility",
    "Status",
    "RequiredLanguages",
    "ActiveSearching",
  ],
  billings_report: [
    "Positions",
    "Speciality",
    "BusinessManagers",
    "CandidateManagers",
    "ClientManagers",
    "Background",
    "startDate",
    "endDate",
    "count",
    "unit",
    "ApplyToStart",
  ],
});

export const haveLeaderboardAccess = [
  ...internalReportsPath,
  ...differentReportsPath,
];

export const questionsTypes = {
  UNIVERSAL: "universal",
  CLIENT: "client",
  POSITION: "position",
};

export const remotePolicy = [
  {
    label: "Full Remote",
    value: "full",
  },
  {
    label: "Hybrid Remote",
    value: "hybrid",
  },
  {
    label: "No Remote",
    value: "none",
  },
];

export const visibility = ["public", "private", "pending_approval"];

export const status = ["open", "closed"];

export const requiredLanguages = [
  {
    label: "Japanese Required",
    value: "japanese",
  },
  {
    label: "English Required",
    value: "english",
  },
  {
    label: "Bilingual (J/E) Required",
    value: "bilingual",
  },
];

export const userTypes = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Recruiter",
    value: "recruiter",
  },
  {
    label: "Sales",
    value: "sales",
  },
  {
    label: "External User (Client)",
    value: "external_user",
  },
  {
    label: "Client Secondary User",
    value: "client_secondary_user",
  },
  {
    label: "Test User",
    value: "test_user",
  },
];

export const maximumExportPaging = { pageSize: 1000, page: 0 };

export const organizations = [
  {
    label: "ExecutiveSearch AI",
    value: "executive_search_ai",
  },
];
