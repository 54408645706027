import LineRecharts from "components/Charts/LineRecharts";
import ModalBox from "components/Modal/ModalBox";
import { ChartsColor } from "helpers/Constant";
import React, { memo, useState } from "react";
import { Col, Row } from "react-bootstrap";

const internalReportDataKeys = ["my", "all"];

const BillingsReportChart = ({ metricsData, lineData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);

  const getDataLabels = (index) => {
    let reportDataLabels = [];
    if (Array.isArray(lineData)) {
      if (lineData[index]?.[0]?.ratioType === "offers") {
        reportDataLabels = [
          { name: "My Total Accepted Offers" },
          { name: "Company Total Accepted Offers" },
        ];
      } else {
        reportDataLabels = [
          { name: "My Revenue Over Time" },
          { name: "Company Revenue Over Time" },
        ];
      }
    }
    return reportDataLabels;
  };

  const openModal = (selectedIndex) => {
    setSelectedLineIndex(selectedIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedLineIndex("");
  };

  return (
    <div className="card card-layout w-100">
      <Row className="gap-2 gap-md-0 g-4">
        <Col md={4}>
          <Row className="h-100 gap-2 gap-md-0">
            {metricsData?.map((data, index) => (
              <Col key={index} className="flex-grow-1 col-12 col-md-6">
                <div
                  className="card-layout h-100 d-flex flex-row flex-md-column justify-content-around rounded-5 align-items-center m-0"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  {internalReportDataKeys.map((key, i) => (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center  align-self-baseline align-self-sm-auto w-100"
                      key={`${key}_${i}`}
                    >
                      <h1
                        className="card-title fw-bolder"
                        style={{ color: ChartsColor[index] }}
                      >
                        {data?.[key]}
                      </h1>
                      <h6 className="metrics-label text-center">
                        {`${key === "my" ? "My" : "Company"} ${
                          !!index
                            ? "Revenue Over Time"
                            : "Total Accepted Offers"
                        }`}
                      </h6>
                    </div>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={8}>
          <Row className="gap-2 gap-md-0 h-100">
            {["offers", "overtime"].map((line, i) => {
              return (
                <Col key={`${i}_${line}`} className="col-12 col-md-6">
                  <div
                    className="card-layout h-100 w-100 m-0"
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <LineRecharts
                      lineData={lineData[i]}
                      datakeys={internalReportDataKeys}
                      dataLabels={getDataLabels(i)}
                      isExpandAllowed={true}
                      openModal={openModal}
                      selectedLineIndex={i}
                      setSelectedLineIndex={setSelectedLineIndex}
                      isYAxisContainsPercentData={false}
                      yAxisLabelName={
                        lineData[i]?.[0]?.ratioType === "offers"
                          ? "Accepted Offers"
                          : "Revenue Over Time"
                      }
                      isSmall
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <ModalBox
        title={
          lineData[selectedLineIndex]?.[0]?.ratioType === "offers"
            ? "Accepted Offers"
            : "Revenue Over Time"
        }
        show={showModal}
        onHide={closeModal}
        content={
          <LineRecharts
            lineData={lineData[selectedLineIndex]}
            datakeys={internalReportDataKeys}
            dataLabels={getDataLabels(selectedLineIndex)}
            isOpenModal={showModal}
            selectedLineIndex={selectedLineIndex}
            isYAxisContainsPercentData={false}
            yAxisLabelName={
              lineData[selectedLineIndex]?.[0]?.ratioType === "offers"
                ? "Accepted Offers"
                : "Revenue Over Time"
            }
          />
        }
      />
    </div>
  );
};

export default memo(BillingsReportChart);
